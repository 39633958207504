import { FormattedMessage } from "@/i18n/i18n-client";
import { H1, H2 } from "@/shared/globals";
import Modal from "@/shared/globals/UiElements/Modal";
import Flex from "@/shared/globals/UiElements/Flex";
import { PrimaryButton } from "@/components/Button";
import { Delete } from "@/assets/Icons/Delete";
import { AlertCircle } from "@/assets/Icons/AlertCircle";
import { useCartData } from "@/lib/cartData/useCartData";
import { useCancelPaymentMutation } from "@/generated/graphql";
import { useStore } from "@/lib/storeData/StoreContext";
import useBreakPoints from "@/shared/utils/useBreakPoints";
import { useRouter } from "@/i18n/i18n-navigation";

const CartPopup = () => {
  const router = useRouter();
  const { cart, setCartPaymentPopUpState, updateCart } = useCartData();
  const { storeId } = useStore();
  const { isTablet, isMobile } = useBreakPoints();

  const handleCart = () => {
    router.push(`/checkout/payment/${cart.id}`);
    setCartPaymentPopUpState(false);
  };
  const [mutationCancelOrder, { loading: cancellingPaymentOrder }] =
    useCancelPaymentMutation();

  const handleShopping = async () => {
    await mutationCancelOrder({
      variables: { storeId, cartId: cart.id },
      onCompleted: () => {
        updateCart({ ...cart, isReserved: false });
        setCartPaymentPopUpState(false);
      },
    });
  };

  if (!cart.isCartPaymentPopUpOpen) return null;

  return (
    <Modal
      modalWidth={isMobile ? "large" : isTablet ? "medium" : "small"}
      closeIcon={
        <div className="text-gray-500 p-xs">
          <Delete />
        </div>
      }
      show={cart.isCartPaymentPopUpOpen || false}
      onClose={() => setCartPaymentPopUpState(false)}
    >
      <Modal.Header boxShadow="none">
        <AlertCircle />
      </Modal.Header>
      <Modal.Body padding="0 20px 20px">
        <Flex column spacing="l">
          <H1 className="!text-lg !font-semibold">
            <FormattedMessage
              defaultMessage="To add more products"
              id="c0tCTm"
            />
          </H1>
          <H2 className="!text-sm !font-normal">
            <FormattedMessage
              defaultMessage="Dear customer, you were about to complete the payment in another tab. To finalize the payment, click <b>View cart</b>. To add new products, click <b>Continue shopping</b>."
              id="pdWMgV"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
              }}
            />
          </H2>
          <Flex fullWidth>
            <PrimaryButton
              outlined
              fullWidth
              rounded
              className="!text-gray-700 !border-gray-300"
              onClick={handleCart}
            >
              <FormattedMessage defaultMessage="View cart" id="5Mx9oZ" />
            </PrimaryButton>
            <PrimaryButton
              className="!bg-warning-600 !border-warning-600"
              fullWidth
              rounded
              onClick={handleShopping}
              isLoading={cancellingPaymentOrder}
            >
              <FormattedMessage
                defaultMessage="Continue shopping"
                id="Yywm0p"
              />
            </PrimaryButton>
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default CartPopup;
