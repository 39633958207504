const Cancel = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M8 0C3.591 0 0 3.591 0 8s3.591 8 8 8 8-3.591 8-8-3.591-8-8-8zm0 1.6c3.544 0 6.4 2.856 6.4 6.4 0 3.544-2.856 6.4-6.4 6.4-3.544 0-6.4-2.856-6.4-6.4 0-3.544 2.856-6.4 6.4-6.4zM5.366 4.234L4.234 5.366 6.87 8l-2.635 2.634 1.132 1.132L8 9.13l2.634 2.635 1.132-1.132L9.13 8l2.635-2.634-1.132-1.132L8 6.87 5.366 4.234z"
      />
    </svg>
  );
};

export { Cancel };
