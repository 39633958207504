import { SectionContentProps, TemplateElement } from "../../types";

const SectionContent: TemplateElement<SectionContentProps> = ({
  children,
  className,
}) => {
  return (
    <div className={"max-w-full px-5 m-auto md:max-w-[85%] " + className}>
      {children}
    </div>
  );
};

export default SectionContent;
